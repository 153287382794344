import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from '../store'

const Auth = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const accessToken = params.get('token')
  const refreshToken = params.get('refreshToken')
  const role = params.get('role')

  const authStore = useStore((stores) => stores.authStore)

  useEffect(() => {
    authStore.login({ accessToken, refreshToken, role })
  }, [])

  useEffect(() => {
    if (authStore.user) {
      navigate('/')
    }
  }, [authStore.user])

  return <div></div>
}

export default Auth
