import { Route, Routes } from 'react-router-dom'
import Auth from './routes/auth'
import Main from './routes/main'
import React, { Component } from 'react'
import logo from './assets/elai-logo.svg'
import { Button, Layout } from 'antd'

const { Content, Header } = Layout

const { REACT_APP_FRONTEND_URL } = process.env

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

const App = () => {
  return (
    <ErrorBoundary
      fallback={
        <Layout>
          <Header>
            <div className="left">
              <img src={logo} width={60} height={24} alt="elai-logo" />
            </div>
            <div className="right">
              <a target="_blank" href={REACT_APP_FRONTEND_URL} rel="noreferrer">
                app.elai.io
              </a>
            </div>
          </Header>
          <Content className="content">
            <div className="chat" style={{ justifyContent: 'center' }}>
              <div className="chat-right" style={{ display: 'flex', justifyContent: 'center' }}>
                We've encountered an error. Please reload page. If problem persists please contact support.
                <br />
                <Button type={'primary'} onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </div>
            </div>
          </Content>
        </Layout>
      }
    >
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </ErrorBoundary>
  )
}

export default App
