import axios from 'axios'
import { notification } from 'antd'
import { clearSession } from '../store/authStore'

const apiUrl = process.env.REACT_APP_API_URL || 'https://apis.elai.io'
const { REACT_APP_FRONTEND_URL, REACT_APP_CHAT_URL } = process.env

let isTokenUpdating = false

const getToken = () => localStorage.getItem('token')

const updateToken = async () => {
  let result = null

  if (!isTokenUpdating) {
    isTokenUpdating = true
    const client = axios.create({ baseURL: apiUrl })
    const refreshToken = localStorage.getItem('refreshToken')
    const userId = localStorage.getItem('userId')
    const headers = { 'Content-Type': 'application/json' }
    try {
      const response = await client.post(`/auth/refresh`, { refreshToken, userId }, { headers })
      localStorage.setItem('refreshToken', response.data.refreshToken)
      localStorage.setItem('token', response.data.accessToken)
      result = response.data.accessToken
    } catch (error) {
      // notification.error({ message: error.message, duration: null, key: 'api-error' })
      clearSession()
      window.location.reload()
    }
    isTokenUpdating = false
  } else {
    while (isTokenUpdating) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((r) => setTimeout(r, 500))
    }
    return getToken()
  }
  return result
}

const request = async ({
  method,
  url,
  data,
  headers,
  params,
  cancelToken,
  allowedStatus,
  errorToDescriptionConverter,
  responseType,
  onBeforeHandleError,
  onUploadProgress,
  abortAfterMs,
}) => {
  const client = axios.create({ baseURL: apiUrl })
  let token = getToken()
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  }
  const subAccountId = localStorage.getItem('subAccountId')
  if (subAccountId) headers['sub-account-id'] = subAccountId

  const adminUserId = localStorage.getItem('adminUserId')
  if (adminUserId) {
    headers['admin-user-id'] = adminUserId
    headers['admin-account-id'] = localStorage.getItem('adminAccountId')
  }

  if (abortAfterMs && !cancelToken) {
    const source = axios.CancelToken.source()
    setTimeout(() => source.cancel(), abortAfterMs)
    cancelToken = source.token
  }

  let response
  try {
    response = await client({ method, url, data, params, headers, responseType, onUploadProgress, cancelToken })
    return response.data
  } catch (error) {
    if (error.response?.status === 401) {
      token = await updateToken()
      if (token) {
        headers.Authorization = `Bearer ${token}`
        response = await client({ method, url, data, params, headers })
        return response.data
      }
      localStorage.removeItem('token')
      document.location.href = `${REACT_APP_FRONTEND_URL}/login?redirectUrl=${REACT_APP_CHAT_URL}`
      return null
    }

    if (axios.isCancel(error)) {
      if (onBeforeHandleError) {
        error.aborted = true
        await onBeforeHandleError(error)
      }
      return false
    }

    if (allowedStatus) {
      if (error.response && allowedStatus === error.response.status) return false
      else if (allowedStatus === 'network-error' && !error.response) return false
    }

    let description = ''
    let message = error.toString()
    if (error.response) {
      if (onBeforeHandleError) await onBeforeHandleError(error.response?.data, error.response?.status)

      if (error.response?.data?.isCustomNotification) {
        message = error.response.data.message
        description = errorToDescriptionConverter ? (
          errorToDescriptionConverter(error.response.data.context)
        ) : (
          <pre>{JSON.stringify({ ...error.response.data.context }, null, 2)}</pre>
        )
      } else {
        switch (error.response.status) {
          case 400:
            message = 'Invalid data'
            break
          case 404:
            message = 'Page not found'
            break
          case 429:
            message = 'Too many requests'
            break
          case 500:
            message = 'Error occured'
            break
          default:
            message = `Server error ${error.response.status}`
            break
        }
        if (error.response.data.message) {
          description += error.response.data.message
        }
        if (error.response.data.length && error.response.data[0].message) {
          description += error.response.data[0].message
        }
      }
    }
    if (![400, 401, 429].includes(error.response?.status)) return false

    notification.destroy('api-error')
    setTimeout(() => {
      // it should be called on next event-loop iteration, because antd&react don't update in time corresponding internal state
      notification.error({ message, description, duration: null, key: 'api-error' })
    }, 100)
    return false
  }
}
const validateContent = (content) => {
  if (content.trim().length === 0) {
    notification.warning({ message: 'Type your text, please' })
    return false
  }
  if (content.length > 100) {
    notification.warning({ message: 'Text is too long. Please reduce to 100 symbols.' })
    return false
  }
  return true
}

const navigateToMainApp = ({ hash }) => {
  const url = new URL(REACT_APP_FRONTEND_URL + '/auth')
  const userId = localStorage.getItem('userId')
  const refreshToken = localStorage.getItem('refreshToken')
  url.searchParams.set('redirectUrl', REACT_APP_CHAT_URL)
  if (userId) url.searchParams.set('refreshToken', refreshToken)
  if (refreshToken) url.searchParams.set('id', userId)
  if (hash) url.hash = hash
  window.location.href = url.toString()
}

export { request, validateContent, navigateToMainApp }
