import domStore from './domStore'
import { authStore } from './authStore'
import appStore from './appStore'

export default () => {
  return {
    authStore: authStore(),
    domStore: domStore(),
    appStore: appStore(),
  }
}
