import React from 'react'
import { Button, Modal } from 'antd'
import avatar from '../assets/dylan_small.png'
import { useStore } from '../store'

const { REACT_APP_FRONTEND_URL, REACT_APP_CHAT_URL } = process.env

const AuthModal = () => {
  const { isNeedAuthModalOpen, setIsNeedAuthModalOpen } = useStore((stores) => stores.domStore)

  return (
    <Modal
      footer={null}
      centered={true}
      open={isNeedAuthModalOpen}
      wrapClassName="auth-modal"
      onCancel={() => setIsNeedAuthModalOpen(false)}
    >
      <div className="title">Sign up to continue</div>
      <div className="avatar-wrapper">
        <div className="avatar">
          <img src={avatar} />
        </div>
        <div className="reply">
          <div className="assistant-wrapper">
            <div className="assistant">
              To continue chatting with me create your account please.
              <div className="polygon polygon-assistant"></div>
            </div>
          </div>
        </div>
      </div>
      <Button
        block
        type="primary"
        className="button-signup"
        href={`${REACT_APP_FRONTEND_URL}/signup?redirectUrl=${REACT_APP_CHAT_URL}`}
      >
        Sign up
      </Button>
      <div className="footer">
        Already have an account?{' '}
        <a href={`${REACT_APP_FRONTEND_URL}/login?redirectUrl=${REACT_APP_CHAT_URL}`}>Log in</a>
      </div>
    </Modal>
  )
}

export default AuthModal
