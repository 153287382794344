import { useState } from 'react'

export default () => {
  const [isNeedAuthModalOpen, setIsNeedAuthModalOpen] = useState(false)
  const [isNeedUpgradeModalOpen, setIsNeedUpgradeModalOpen] = useState(false)
  const [isClearChatModalOpen, setIsClearChatModalOpen] = useState(false)

  return {
    isNeedAuthModalOpen,
    setIsNeedAuthModalOpen,
    isNeedUpgradeModalOpen,
    setIsNeedUpgradeModalOpen,
    isClearChatModalOpen,
    setIsClearChatModalOpen,
  }
}
