import { request } from '../utils/api'
import { useStore } from '../store'
import { notification } from 'antd'

let peerConnection

/**
 * for communicate with webRTC server
 *
 * @param {HTMLVideoElement} videoRef reference to video tag
 * @param {function} setIsSessionActive set state of session established or not
 * @param {function} setIsCreatingSession set if session is currently creates
 * @param {Object} selectedAvatar object represents current selected avatar ({ name, code, defaultVoice } currently used)
 * @param {string} voiceId string represents voiceId
 * @param {function} setIsSendingRenderRequest setter for state of currently sending request for rendering text on the backend
 *
 * @return {{startStream: function, endStream: function, getStream: function, clearStreamSessionData: function}}
 * startStream: function for initiate webRTC session
 * stop: function for stop webRTC session
 * getStream: check backend's for a current session active or not
 * clearStreamSessionData: clear's current session state
 */

const useWebRTC = ({
  videoRef,
  setIsSessionActive,
  setIsCreatingSession,
  setCreatingSessionDescription,
  selectedAvatar,
  voiceId,
  host,
  setIsSendingRenderRequest,
}) => {
  const { getStreamId, setStreamId, clearStreamDataFromStorage } = useStore((stores) => stores.appStore)

  const clearStreamSessionData = () => {
    if (!videoRef.current.paused) videoRef.current.srcObject = null
    setIsSessionActive(false)
    setIsSendingRenderRequest(false)
    clearStreamDataFromStorage()
  }

  const endStream = async () => {
    if (getStreamId()) {
      await request({
        method: 'delete',
        url: `/stream/${getStreamId()}`,
      })
    }
    clearStreamSessionData()
  }

  const processOffer = async ({ offer, iceServers }) => {
    peerConnection = new RTCPeerConnection({ iceTransportPolicy: 'relay', iceServers })
    peerConnection.onicecandidate = async (e) => {
      if (!e.candidate) {
        return
      }
      const result = await request({
        method: 'post',
        url: `/stream/candidate/${getStreamId()}`,
        data: { candidate: e.candidate },
      })
      if (!result) {
        clearStreamSessionData()
        setIsCreatingSession(false)
        setCreatingSessionDescription('')
      }
    }

    peerConnection.onnegotiationneeded = (e) => {
      //
    }

    peerConnection.onicegatheringstatechange = (e) => {
      const { iceGatheringState } = e.target
      if (iceGatheringState === 'complete') {
        setIsSessionActive(true)
        setIsCreatingSession(false)
        setCreatingSessionDescription('')
        const isPlaying =
          videoRef?.current.currentTime > 0 &&
          !videoRef?.current.paused &&
          !videoRef?.current.ended &&
          videoRef?.current.readyState > videoRef?.current.HAVE_CURRENT_DATA
        if (videoRef?.current?.paused && videoRef?.current?.srcObject && !isPlaying) {
          videoRef?.current.play()
        }
      }
    }

    peerConnection.ontrack = async (event) => {
      const [remoteStream] = event.streams
      videoRef.current.srcObject = remoteStream
    }

    peerConnection.onerror = (event) => {
      //
    }

    try {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
    } catch (e) {
      setIsCreatingSession(false)
      setCreatingSessionDescription('')
      clearStreamSessionData()
      notification.error({
        message: "Can't establish connection",
        description: 'We were not able to establish connection. Stream returned no offer. Please try again.',
        duration: 0,
      })
      return
    }

    const dataChannel = peerConnection.createDataChannel('test')
    dataChannel.onopen = () => console.log('data channel is opened')
    dataChannel.onerror = (error) => console.error('channel error: ', error)
    dataChannel.onmessage = (e) => console.log('channel message: ', e.data)

    const answer = await peerConnection.createAnswer()
    await peerConnection.setLocalDescription(answer)
    const result = await request({ method: 'put', url: `/stream/${getStreamId()}`, data: { answer } })
    if (!result) endStream()
  }

  const getStream = async () => {
    if (!getStreamId()) return
    return await request({
      method: 'get',
      url: `/stream/${getStreamId()}`,
      allowedStatus: 400,
    })
  }

  const handleCreateNewStream = (data, status) => {
    if (![429, 400].includes(status)) {
      notification.error({
        message: "Can't start stream",
        description: "Can't start a new stream, please try again later.",
      })
    }
  }

  const createNewStream = async () => {
    const data = {
      avatarCode: selectedAvatar?.code,
      voiceId,
      voiceProvider: 'elevenlabs',
      host,
    }
    return await request({ method: 'post', data, url: `/stream`, onBeforeHandleError: handleCreateNewStream })
  }

  const startStream = async (stream) => {
    if (!stream) {
      stream = await getStream()
      // clear data and initiate new stream session if stream not found or is not active
      clearStreamSessionData()
      stream = await createNewStream()
    }
    if (!stream?.webrtcData) {
      setIsCreatingSession(false)
      setCreatingSessionDescription('')
      setIsSessionActive(false)
      return
    }
    const { offer, iceServers } = stream.webrtcData
    setStreamId(stream.id)
    setIsCreatingSession(true)

    await processOffer({ offer, iceServers })
  }

  return { startStream, endStream, getStream, clearStreamSessionData }
}

export { useWebRTC }
