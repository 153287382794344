import React, { useEffect } from 'react'
import { Layout, Modal } from 'antd'
import { useStore } from '../store'
import logo from '../assets/elai-logo.svg'
import AuthModal from '../components/AuthModal'
import ProfileMenu from '../components/ProfileMenu'
import MinutesModal from '../components/NeedUpgradeModal'
import '../assets/styles/styles.less'
import Stream from './stream'

const { Header, Content, Footer } = Layout

const { REACT_APP_FRONTEND_URL } = process.env

const Main = () => {
  const { setMessages } = useStore((stores) => stores.appStore)
  const { user, refreshSession } = useStore((stores) => stores.authStore)
  const { isNeedAuthModalOpen, isNeedUpgradeModalOpen, isClearChatModalOpen, setIsClearChatModalOpen } = useStore(
    (stores) => stores.domStore,
  )

  const clearChatHistory = () => {
    setMessages([])
    setIsClearChatModalOpen(false)
  }

  useEffect(() => {
    if (!user?.id) return
    refreshSession()
  }, [user?.id])

  return (
    <Layout>
      <Header>
        <div className="left">
          <img src={logo} width={60} height={24} alt="elai-logo" />
        </div>
        <div className="right">
          {user?.id ? (
            <ProfileMenu />
          ) : (
            <a target="_blank" href={REACT_APP_FRONTEND_URL} rel="noreferrer">
              app.elai.io
            </a>
          )}
        </div>
      </Header>
      <Content className="content">
        <Stream />
      </Content>
      <Footer>
        <div>
          This is a demo of{' '}
          <a href="https://elai.readme.io/reference/realtime-api" target="_blank" rel="noreferrer">
            Elai Real-time API
          </a>
          . This demo supports only English and powered by simple LLM.
        </div>
        <div>In production you can use any language and any engine for text.</div>
      </Footer>
      {isNeedAuthModalOpen && <AuthModal />}
      {isNeedUpgradeModalOpen && <MinutesModal />}
      <Modal
        title="Are you shure you want to delete chat history?"
        wrapClassName="chat-app-modal"
        open={isClearChatModalOpen}
        onOk={clearChatHistory}
        onCancel={() => setIsClearChatModalOpen(false)}
        okText="Delete chat history"
        cancelText="Cancel"
        okButtonProps={{ className: 'warning' }}
      >
        <p>This action cannot be undone, ensuring clean state of your conversations</p>
      </Modal>
    </Layout>
  )
}

export default Main
