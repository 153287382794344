import React from 'react'
import { formatSeconds } from '../utils/helpers'
import { Progress } from 'antd'
import { useStore } from '../store'
import { navigateToMainApp } from '../utils/api'

const MinutesProgressBar = ({ showAdditional = false }) => {
  const { user } = useStore((stores) => stores.authStore)

  if (user?.account?.minutesUsed === undefined) return null
  return (
    <div className="minutes-info">
      <p style={{ fontWeight: 500, margin: 0 }}>
        <span style={{ color: '#aaaaaa' }}>Minutes used</span>{' '}
        <span>
          {formatSeconds(Math.round(user.account.minutesUsed * 60)) || '0m'}/{user.account.minutesAvailable}m
        </span>
      </p>
      <Progress
        showInfo={false}
        strokeColor="rgba(72, 104, 255, 1)"
        percent={(user.account.minutesUsed * 100) / user.account.minutesAvailable}
        status={(user.account.minutesUsed * 100) / user.account.minutesAvailable > 100 ? 'exception' : 'normal'}
      />
      {showAdditional && (
        <div>
          <a onClick={() => navigateToMainApp({ hash: 'subscription' })}>
            <b>Upgrade</b>
          </a>{' '}
          for more minutes
        </div>
      )}
    </div>
  )
}

export default MinutesProgressBar
