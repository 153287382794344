import { useState } from 'react'
import { request } from '../utils/api'

const storedFields = [
  { name: 'userId' },
  { name: 'name' },
  { name: 'email' },
  { name: 'status' },
  { name: 'role' },
  { name: 'accountRole' },
  { name: 'token' },
  { name: 'refreshToken' },
  { name: 'parentAccountId' },
  { name: 'account', stringify: true },
  { name: 'messages', stringify: true },
]

const clearSession = () => {
  for (const field of storedFields) {
    localStorage.removeItem(field.name)
  }
}

const getUserFromLS = () => {
  const id = localStorage.getItem('userId')
  if (!id) return null

  const name = localStorage.getItem('name')
  const role = localStorage.getItem('role')
  const email = localStorage.getItem('email')
  const status = localStorage.getItem('status')
  const accountRole = localStorage.getItem('accountRole')
  const adminAccountId = localStorage.getItem('adminAccountId')
  const parentAccountId = localStorage.getItem('parentAccountId')

  let account = {}
  try {
    account = JSON.parse(localStorage.getItem('account')) || {}
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return {
    id,
    name,
    email,
    status,
    role,
    accountRole,
    account,
    adminAccountId,
    parentAccountId,
    isAdmin: role !== 'user',
  }
}

const initialUserState = getUserFromLS()

const authStore = () => {
  const [user, setUser] = useState(initialUserState)

  const refreshSession = async () => {
    const isLoggedAsUser = !!localStorage.getItem('adminUserId')
    const data = await request({ method: 'get', url: 'users/me', allowedStatus: isLoggedAsUser ? 500 : null })
    if (!data) {
      if (isLoggedAsUser) {
        // incorrect adminUserId or user is not exist anymore
        localStorage.removeItem('adminUserId')
        localStorage.removeItem('adminAccountId')
        await refreshSession()
      }
      return
    }

    storedFields.forEach((field) => {
      const value = data[field.name]
      if (value) localStorage.setItem(field.name, field.stringify ? JSON.stringify(value) : value)
    })
    setUser(getUserFromLS())
    return data
  }

  const syncAvailableMinutes = async () => {
    const data = await request({ method: 'get', url: 'users/me' })
    if (!data) return
    if (
      data.account.minutesAvailable !== user.account.minutesAvailable ||
      data.account.minutesUsed !== user.account.minutesUsed
    ) {
      localStorage.setItem('account', JSON.stringify(data.account))

      setUser(getUserFromLS())
    }
  }

  const syncGptUsageCounters = async () => {
    const data = await request({ method: 'get', url: 'users/me' })
    if (!data) return
    const { gptUsageCounters } = data.account
    if (gptUsageCounters.tokens > (user.account.gptUsageCounters?.tokens || 0)) {
      localStorage.setItem('account', JSON.stringify(data.account))
      setUser(getUserFromLS())
    }
    return gptUsageCounters.tokens
  }

  const login = async (data) => {
    if (data.accessToken) {
      localStorage.setItem('token', data.accessToken)
      localStorage.setItem('refreshToken', data.refreshToken)
      localStorage.setItem('role', data.role)

      await refreshSession()
    }
  }

  const logout = async () => {
    localStorage.removeItem('adminUserId')
    localStorage.removeItem('adminAccountId')
    if (user.adminAccountId) {
      await refreshSession()
    } else clearSession()
    setUser(getUserFromLS())
  }

  const adminLoginAs = async ({ accountId, userId }) => {
    if (!userId) {
      const data = await request({ method: 'get', url: `admin/accounts/owner/${accountId}` })
      localStorage.setItem('adminUserId', data.userId)
      localStorage.setItem('adminAccountId', accountId)
    } else {
      localStorage.setItem('adminUserId', userId)
      localStorage.setItem('adminAccountId', accountId)
    }
    await refreshSession()
  }

  const checkAdminLoginedAsUser = () => !!localStorage.getItem('adminUserId')

  return {
    user,
    login,
    logout,
    adminLoginAs,
    checkAdminLoginedAsUser,
    refreshSession,
    syncAvailableMinutes,
    syncGptUsageCounters,
  }
}

export { authStore, clearSession }
