import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import App from './App'
import { StoreProvider } from './store'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StoreProvider>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#4868ff',
            colorPrimaryActive: '#2d4ffc',
            colorError: '#cf5d60',
          },
          components: {
            Layout: {
              headerBg: '#FFFFFF',
              footerBg: '#F2F5F6',
            },
            Button: {
              borderRadius: 8,
              borderRadiusLG: 8,
            },
            Input: {
              borderRadius: 10,
            },
          },
        }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </StoreProvider>,
)
