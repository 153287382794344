import { useEffect, useRef, useState } from 'react'
import { notification } from 'antd'

/**
 * use it for convert to speech to text using browser's webkitSpeechRecognition Class
 *
 * @param {function} setNewMessage function that accepts recognized text
 *
 * @return {{record: function, stop: function, isRecordingAudio: Boolean, isRecognitionEnabled: Boolean}}
 * record: function that initiates audio recording
 * stop: function that stops audio recording
 * isRecordingAudio: flag indicates is it currently recording audio
 * isRecognitionEnabled: indicates possibility of recording audio
 */

export const useAudio = ({ setNewMessage, setIsSendingRenderRequest }) => {
  const [isRecordingAudio, setIsRecordingAudio] = useState(false)
  const [ignoreOnEnd, setIgnoreOnEnd] = useState(null)
  const { current: recognition } = useRef(
    window.webkitSpeechRecognition ? new window.webkitSpeechRecognition() : undefined,
  )

  let finalTranscript = ''

  const record = () => {
    if (isRecordingAudio) {
      recognition.stop()
      return
    }
    finalTranscript = ''
    recognition.start()
    setIgnoreOnEnd(false)
  }

  const stop = () => {
    setIsRecordingAudio(false)
    setIsSendingRenderRequest(true)
  }

  useEffect(() => {
    if (!recognition) return
    recognition.continuous = true
    recognition.lang = 'en-US'
    recognition.onstart = function () {
      setIsRecordingAudio(true)
    }

    recognition.onerror = (event) => {
      setIgnoreOnEnd(true)
      if (event.error === 'not-allowed') {
        notification.warning({ message: 'Please allow audio recording to use this demo', duration: 4 })
      }
    }

    recognition.onend = function () {
      setIsRecordingAudio(false)
      if (ignoreOnEnd) return
      if (!finalTranscript) {
        notification.warning({ message: 'No message recorded. Please try speaking louder or reducing noise.' })
        setIsSendingRenderRequest(false)
        return
      }
      setNewMessage(finalTranscript)
      finalTranscript = ''
    }

    recognition.onresult = (event) => {
      if (typeof event.results == 'undefined') {
        recognition.onend = null
        recognition.stop()
        return
      }
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript
          recognition.stop()
        }
      }
    }
  }, [recognition])

  return { record, stop, isRecordingAudio, isRecognitionEnabled: !!recognition }
}
