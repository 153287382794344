import React from 'react'
import { Dropdown, Typography } from 'antd'
import Icon from './Icon'
import { useStore } from '../store'
import MinutesProgressBar from './MinutesProgressBar'

const ProfileMenu = () => {
  const { user, logout } = useStore((stores) => stores.authStore)
  const { setIsClearChatModalOpen } = useStore((stores) => stores.domStore)
  const { messages } = useStore((stores) => stores.appStore)

  const profileMenuItems = [
    {
      label: (
        <div className="profile-username-email">
          <p>{user.name}</p>
          <Typography.Text type="secondary">{user.email}</Typography.Text>
        </div>
      ),
      key: 'name',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div className="profile-menu-minutes-wrapper">
          <MinutesProgressBar showAdditional={true} />
        </div>
      ),
    },
    {
      label: 'Clear chat history',
      disabled: !messages?.length,
      key: 'clear_history',
      icon: <Icon name="conversation_remove" />,
      onClick: () => setIsClearChatModalOpen(true),
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <Icon name="logout" />,
      onClick: logout,
    },
  ]

  return (
    <Dropdown
      menu={{
        items: profileMenuItems,
        className: 'profile-dropdown-menu',
      }}
      trigger={['click']}
    >
      <div className="profile-dropdown-title">
        {user.name}
        <>
          <Icon name="down_arrow" className="down_icon" />
          <Icon name="up_arrow" className="up_icon" />
        </>
      </div>
    </Dropdown>
  )
}

export default ProfileMenu
