import { useCallback, useEffect, useState } from 'react'

export default () => {
  const [messages, setMessages] = useState([])

  const getStreamId = useCallback(() => localStorage.getItem('streamId'), [localStorage.getItem('streamId')])

  const setStreamId = (value) => localStorage.setItem('streamId', value)

  const clearStreamDataFromStorage = () => {
    localStorage.removeItem('streamId')
    localStorage.removeItem('messages')
    setMessages([])
  }

  const refreshStreamState = () => {
    setMessages(JSON.parse(localStorage.getItem('messages')) ?? [])
  }

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages))
  }, [messages?.length])

  return {
    messages,
    setMessages,
    getStreamId,
    setStreamId,
    clearStreamDataFromStorage,
    refreshStreamState,
  }
}
