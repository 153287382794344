import { useEffect, useState } from 'react'
import { notification } from 'antd'

export const useTimer = ({ sessionEndTime, clearStreamSessionData }) => {
  const [[diffM, diffS, diff], setDiff] = useState([0, 0])
  const [tick, setTick] = useState(false)

  useEffect(() => {
    const diff = (sessionEndTime - new Date().getTime()) / 1000
    if (diff < 0 && sessionEndTime) {
      notification.success({ message: 'Your session was expired. 2 minutes passed from last question.', duration: 0 })
      clearStreamSessionData()
      return
    }
    setDiff([Math.floor((diff / 60) % 60), Math.floor(diff % 60), diff])
  }, [tick, sessionEndTime])

  useEffect(() => {
    const timerID = setInterval(() => setTick(!tick), 1000)
    return () => clearInterval(timerID)
  }, [tick])

  return {
    sessionEndMins: diffM.toString().padStart(2, '0'),
    sessionEndSecs: diffS.toString().padStart(2, '0'),
    diff,
  }
}
