import React from 'react'
import { Button, Modal } from 'antd'
import { useStore } from '../store'
import MinutesProgressBar from './MinutesProgressBar'
import { navigateToMainApp } from '../utils/api'

const AuthModal = () => {
  const { isNeedUpgradeModalOpen, setIsNeedUpgradeModalOpen } = useStore((stores) => stores.domStore)

  return (
    <Modal
      footer={null}
      open={isNeedUpgradeModalOpen}
      wrapClassName="minutes-modal"
      centered={true}
      onCancel={() => setIsNeedUpgradeModalOpen(false)}
    >
      <div className="title">Sorry, you do not have enough minutes to receive new answers</div>
      <div className="subtitle">Upgrade your account to continue chatting.</div>
      <div className="minutes-wrapper">
        <MinutesProgressBar />
      </div>
      <Button type="primary" className="button-signup" onClick={() => navigateToMainApp({ hash: 'subscription' })}>
        Upgrade my account
      </Button>
    </Modal>
  )
}

export default AuthModal
