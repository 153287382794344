// const redirectToApp = (url, options) => {
//
// }

const formatSeconds = (duration) => {
  if (!duration) return ''
  const minutes = Math.floor(duration / 60)
  const seconds = Math.round(duration - minutes * 60)
  return duration < 60 ? `${seconds}s` : `${minutes}m ${seconds > 0 ? seconds + 's' : ''}`
}

export { formatSeconds }
